<template>
  <div class="zone">
    <TJDetailTitle :titleData="'基本信息'" />
    <TJDetailMenu :dataList="dataList" />

    <TJDetailTitle :titleData="'质检单'" />
    <div class="printBox" v-if="detailData.is_pdf">
      <embed 
       id="printMe"
       :src="detailData.report_img.file_url[0]"
       type="application/pdf"
       width="100%"
       height="800px" />
    </div>
    <div class="imgBox" v-else>
      <el-image
        v-for="(item, index) in detailData.report_img
          ? detailData.report_img.file_url
          : ''"
        :key="index"
        style="width: 300px; height: 300px"
        :preview-src-list="detailData.report_img.file_url"
        :src="item"
        :fit="fit"
      ></el-image>
    </div>
  </div>
</template>

<script>
import apiDetail from '@/api/searchDetail.js'
import TJDetailTitle from '@/components/TJDetailTitle'
import TJDetailMenu from '@/components/TJDetailMenu'
export default {
  name: 'ReportsDetail',
  components: { TJDetailTitle, TJDetailMenu },

  data() {
    return {
      dataList: [
        {
          name: '产品名称',
          value: '',
          width: '33%',
          bottomBorder: false,
        },
        {
          name: '规格型号',
          value: '',
          width: '33%',
          bottomBorder: false,
        },
        {
          name: '批号',
          value: '',
          width: '33%',
          bottomBorder: false,
        },
      ],

      detailData: {},
      fit: '',
      url: '',
    }
  },
  created() {
    this.detailId = this.$route.query.id
    this.gitreportDetail()
  },
  methods: {
    gitreportDetail() {
      apiDetail.reportDetail({ report_id: this.detailId }).then((res) => {
        this.detailData = res.data
        this.dataList[0].value = this.detailData.reagent_name
        this.dataList[1].value = this.detailData.format
        this.dataList[2].value = this.detailData.batch
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.imgBox {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  .el-image {
    margin-bottom: px(20);
  }
  .el-image {
    margin-left: px(55);
  }
}
</style>
